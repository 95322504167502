import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import 'animate.css';
import directive from './utils/change'
Vue.directive('change',directive)
// 字体
import '@/assets/font/font.css';


import { numberToCurrencyNo } from '@/utils/numberToCurrency'
// 配置全局过滤器，实现数字千分位格式
Vue.filter('numberToCurrency', numberToCurrencyNo)
// 引入 axios
// import axios from 'axios'
// import VueAxios from 'vue-axios' 
// Vue.use(VueAxios,axios);

// axios.defaults.baseURL = '/api';
// //超时设置
// axios.defaults.timeout = 3000;

//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

new Vue({
  router,
  store,
  el: '#app',
  render: (h) => h(App),
}).$mount('#app');

Vue.use(ElementUI);


